import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Box, Heading, Text, VStack, Divider, Grid } from "@chakra-ui/react";
import Container from "src/components/Container";
import TheFairBanner from "src/components/banners/TheFairBanner";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/the-fair/" } }) {
      frontmatter {
        banner_text
        section_one
        entertainment {
          entertainment_name
          entertainment_description
        }
        section_three
        section_four
        section_five
        section_six
        section_seven
      }
    }
  }
`;

const TheFair = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeadSEO
        title="The Fair | Evandale Village Fair"
        description="The town of Evandale bands together each year to combine market
              stalls and a variety of entertainment in the streets and parks creating the ambience of a colonial country fair."
        slug="/the-fair/"
      />
      <TheFairBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="55% 50%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Heading as="h1" size="2xl">
              {page.banner_text}
            </Heading>
          </VStack>
        </Container>
      </TheFairBanner>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_one} />
          </VStack>
        </Container>
      </Box>
      <Box as="section" color="white" bg="teal.700">
        <Container size="md" py="6rem">
          <Heading size="xl">Entertainment Provided By</Heading>
          <VStack mt="2rem" align="flex-start" spacing="1.5rem">
            {page.entertainment.map((act) => {
              return (
                <>
                  <Divider borderColor="orange.300" borderBottomWidth="2px" />
                  <VStack align="flex-start" spacing="0.25rem">
                    <Heading as="h3" size="md" fontFamily="body">
                      {act.entertainment_name}
                    </Heading>
                    <Text>{act.entertainment_description}</Text>
                  </VStack>
                </>
              );
            })}
          </VStack>
        </Container>
      </Box>
      <Box as="section">
        <Container size="md" pt="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_three} />
          </VStack>
          <Divider mt="3rem" />
          <VStack mt="3rem" align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_four} />
          </VStack>
        </Container>

        <Container size="lg" pt="3rem" pb="6rem">
          <Grid
            gridTemplateColumns="repeat(auto-fit, minmax(20rem, 1fr))"
            gap="2rem"
          >
            <VStack align="flex-start" spacing="1rem">
              <ReactMarkdown children={page.section_five} />
            </VStack>
            <VStack align="flex-start" spacing="1rem">
              <ReactMarkdown children={page.section_six} />
            </VStack>
            <VStack align="flex-start" spacing="1rem">
              <ReactMarkdown children={page.section_seven} />
            </VStack>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default TheFair;
